import React, { FC, ReactNode } from 'react'
import { getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'

import { getGatsbyImageNode } from '../../utils/getGatsbyImageNode'

import LayoutLibrary from '@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-default'

import '../styles/variables.scss'

export type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      company: companyJson {
        name
        slogan
        address {
          cidade
        }
      }
      logoHorizontal: file(name: { eq: "logo-horizontal" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `)

  const company = data.company
  const logoHorizontal = getImage(
    data.logoHorizontal.childImageSharp.gatsbyImageData
  )!

  return (
    <LayoutLibrary
      logoFooter={getGatsbyImageNode(logoHorizontal, `Logo ${company}`, true)}
      logoTopbar={getGatsbyImageNode(logoHorizontal, `Logo ${company}`, true)}
      companyName={company.name}
      city={company.address.city}
      slogan={company.slogan}
      hasSloganFooter={false}
      badge={{ type: 'digital' }}
      navItems={[
        {
          label: 'Home',
          path: '/'
        },
        {
          label: 'Serviços',
          path: '/servicos'
        },
        {
          label: 'Contato',
          path: '/contato'
        }
      ]}
      facebook=""
      instagram=""
      linkedin=""
    >
      {children}
    </LayoutLibrary>
  )
}

export default Layout
